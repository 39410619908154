import { useContext } from 'react';
import { CubeContext } from '@cubejs-client/react';
import { CubejsApi, Query, ResultSet } from '@cubejs-client/core';
import posthog from 'posthog-js';

export default function useCubejsApiWrapper () {
  const context = useContext(CubeContext);

  const cubejsApiWrapper = async (
    query: Query,
    options?: { [key: string]: string },
    providedCubejsApi?: CubejsApi,
  ): Promise<ResultSet<any>> => {
    const api = providedCubejsApi || context.cubejsApi;

    if (!api) {
      throw new Error('CubejsApi is not available');
    }

    try {
      const result: ResultSet<any> = await api.load(query, options);
      return result;
    } catch (error) {
      posthog.capture('CubeJS Loading Error', {
        errorMessage: error.message,
      });
      throw error;
    }
  };

  return cubejsApiWrapper;
}

