import type { NextPage } from 'next';
import { useContext, useState, useEffect, useCallback } from 'react';
import { CubeProvider, CubeContext, CubeContextProps } from '@cubejs-client/react';
import cubejs, { CubejsApi } from '@cubejs-client/core';
import { useAuth0 } from '@auth0/auth0-react';
import { useGlobalState } from './globalState';

export const useCubeContext = () => useContext<CubeContextProps>(CubeContext);

export const CubeJSProvider: NextPage = ({ children }) => {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const {
    organization, setLoadingCubejs, setApiHeaders, handleLoadCompanyMetrics, handleGetMaxEffectiveTo, handleLoadPeopleMetrics, getTotalData
  } = useGlobalState();
  const [cubejsApi, setCubejsApi] = useState<CubejsApi | null>(null);

  const initCubejs = useCallback(async () => {
    if (organization) {
      setLoadingCubejs(true);
      const accessToken = await getAccessTokenSilently({
        audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
        scope: 'openid profile email',
        organization: organization.id,
        ignoreCache: true
      });
      const options = { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${accessToken}` } };
      setApiHeaders(options);
      setCubejsApi(cubejs({
        apiUrl: process.env.NEXT_PUBLIC_CUBEJS_API_URL as string,
        headers: { Authorization: `${accessToken}` },
      }));
      setLoadingCubejs(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, organization]);

  useEffect(() => {
    if (!isLoading && isAuthenticated && organization) {
      initCubejs();
    }
  }, [initCubejs, isAuthenticated, isLoading, organization]);

  useEffect(() => {
    if (cubejsApi) {
      handleLoadCompanyMetrics(cubejsApi);
      handleLoadPeopleMetrics(cubejsApi);
      handleGetMaxEffectiveTo(cubejsApi);
      getTotalData(cubejsApi);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cubejsApi]);

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      {children}
    </CubeProvider>
  );
};
