import type { NextPage } from 'next';
import { useContext, createContext, useState, useEffect } from 'react';
import { Segment, Table } from '../types/Common.interfaces';
import { AudienceFilter, CombinedAudienceFilter } from '../types/Cube.interfaces';
import { useGlobalState } from './globalState';
import { Query, DateRange, ResultSet } from '@cubejs-client/core';
import { GridColDef } from '@mui/x-data-grid-pro';
import { format, subDays } from 'date-fns';
import useCubejsApiWrapper from '../apiHelpers/cubejsWrapper';

type reportProps = {
  reportName: string,
  setReportName: (value: string) => void;
  segmentSelected: string,
  setSegmentSelected: (value: string) => void;
  segments: Segment[];
  setSegments: (value: Segment[]) => void;
  selectedStatus: string[];
  setSelectedStatus: (value: string[]) => void;
  selectedTable: string;
  setSelectedTable: (value: string) => void;
  table: Table | null;
  setTable: (value: Table | null) => void;
  dateRangeValue: string;
  setDateRangeValue: (value: string) => void;
  fromDate: Date | null;
  setFromDate: (value: Date | null) => void;
  toDate: Date | null;
  setToDate: (value: Date | null) => void;
  reportSegmentFilters: AudienceFilter[];
  setReportSegmentFilters: (value: AudienceFilter[]) => void;
  statuses: { value: string, label: string, tooltip: string }[];
  dateRangeOptions: { value: string, label: string }[];
  runReport: boolean;
  setRunReport: (value: boolean) => void;
  showReportData: boolean;
  setShowReportData: (value: boolean) => void;
  reportData: any[];
  setReportData: (value: any[]) => void;
  getPeopleQuery: (
    pageNo: number, pageSizeNo: number, sortColumn: string, sortValue: 'asc' | 'desc',
    filters: (AudienceFilter | CombinedAudienceFilter)[], additionalColumns: boolean, dateRange: string[], includesRemoved: boolean
  ) => Query;
  getCompanyQuery: (
    pageNo: number, pageSizeNo: number, sortColumn: string, sortValue: 'asc' | 'desc',
    filters: (AudienceFilter | CombinedAudienceFilter)[], additionalColumns: boolean, dateRange: string[], includesRemoved: boolean
  ) => Query;
  totalCount: number;
  setTotalCount: (value: number) => void;
  updatedStatusAdded: boolean;
  setUpdatedStatusAdded: (value: boolean) => void;
  compareData: any[];
  setCompareData: (value: any[]) => void;
  compareValues: boolean;
  setCompareValues: (value: boolean) => void;
  handleGetCompareData: (
    setData: (value: any[]) => void, tableName: string, tableId: string, columns: GridColDef[],
    pageSize: number, dates: DateRange, data: any[]
  ) => void;
  getPeopleExportQuery: (
    columns: string[], filters: (AudienceFilter | CombinedAudienceFilter)[], dateRange: string[], includesRemoved: boolean
  ) => Query;
  getCompanyExportQuery: (
    columns: string[], filters: (AudienceFilter | CombinedAudienceFilter)[], dateRange: string[], includesRemoved: boolean
  ) => Query;
  buildReportFilter: (tableName: string, statuses: string[]) => any[];
  buildDateRange: (dateRangeValue: string, fromDate: Date | null, toDate: Date | null) => string[];
  dateArray: (startDateValue: Date, endDateValue: Date) => string[];
  loadingData: boolean;
  setLoadingData: (value: boolean) => void;
  modifyFilters: (filters: AudienceFilter[], removedCube: string) => AudienceFilter[];
}

const DEFAULT_PROPS = {
  reportName: '',
  setReportName: (value: string) => {},
  segmentSelected: '',
  setSegmentSelected: (value: string) => {},
  segments: [],
  setSegments: (value: Segment[]) => {},
  selectedStatus: [],
  setSelectedStatus: (value: string[]) => {},
  selectedTable: '',
  setSelectedTable: (value: string) => {},
  table: null,
  setTable: (value: Table | null) => {},
  dateRangeValue: '',
  setDateRangeValue: (value: string) => {},
  fromDate: null,
  setFromDate: (value: Date | null) => {},
  toDate: null,
  setToDate: (value: Date | null) => {},
  reportSegmentFilters: [],
  setReportSegmentFilters: (value: AudienceFilter[]) => {},
  statuses: [],
  dateRangeOptions: [],
  runReport: false,
  setRunReport: (value: boolean) => {},
  showReportData: false,
  setShowReportData: (value: boolean) => {},
  reportData: [],
  setReportData: (value: any[]) => {},
  getPeopleQuery: (
    pageNo: number, pageSizeNo: number, sortColumn: string, sortValue: 'asc' | 'desc',
    filters: (AudienceFilter | CombinedAudienceFilter)[], additionalColumns: boolean, dateRange: string[], includesRemoved: boolean
  ) => { return { }; },
  getCompanyQuery: (
    pageNo: number, pageSizeNo: number, sortColumn: string, sortValue: 'asc' | 'desc',
    filters: (AudienceFilter | CombinedAudienceFilter)[], additionalColumns: boolean, dateRange: string[], includesRemoved: boolean
  ) => { return { }; },
  totalCount: 0,
  setTotalCount: (value: number) => {},
  updatedStatusAdded: false,
  setUpdatedStatusAdded: (value: boolean) => {},
  compareData: [],
  setCompareData: (value: any[]) => {},
  compareValues: false,
  setCompareValues: (value: boolean) => {},
  handleGetCompareData: (
    setData: (value: any[]) => void, tableName: string, tableId: string, columns: GridColDef[],
    pageSize: number, dates: DateRange, data: any[]
  ) => {},
  getPeopleExportQuery: (
    columns: string[], filters: (AudienceFilter | CombinedAudienceFilter)[], dateRange: string[], includesRemoved: boolean
  ) => { return { }; },
  getCompanyExportQuery: (
    columns: string[], filters: (AudienceFilter | CombinedAudienceFilter)[], dateRange: string[], includesRemoved: boolean
  ) => { return { }; },
  buildReportFilter: (tableName: string, statuses: string[]) => { return []; },
  buildDateRange: (dateRangeValue: string, fromDate: Date | null, toDate: Date | null) => { return []; },
  dateArray: (startDateValue: Date, endDateValue: Date) => { return []; },
  loadingData: false,
  setLoadingData: (value: boolean) => {},
  modifyFilters: (filters: AudienceFilter[], removedCube: string) => { return []; },
};

export const ReportContext = createContext<reportProps>(DEFAULT_PROPS);

export const useReportState = () => useContext(ReportContext);

export const ReportProvider: NextPage = ({ children }) => {
  const { organization, companyColumns, peopleColumns } = useGlobalState();
  const [reportName, setReportName] = useState<string>('');
  const [segmentSelected, setSegmentSelected] = useState<string>('');
  const [segments, setSegments] = useState<Segment[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [selectedTable, setSelectedTable] = useState<string>('');
  const [table, setTable] = useState<Table | null>(null);
  const [dateRangeValue, setDateRangeValue] = useState<string>('');
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [reportSegmentFilters, setReportSegmentFilters] = useState<AudienceFilter[]>([]);
  const [runReport, setRunReport] = useState<boolean>(false);
  const [showReportData, setShowReportData] = useState<boolean>(false);
  const [reportData, setReportData] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [updatedStatusAdded, setUpdatedStatusAdded] = useState<boolean>(false);
  const [compareData, setCompareData] = useState<any[]>([]);
  const [compareValues, setCompareValues] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [statuses] = useState<{ value: string, label: string, tooltip: string }[]>([
    { value: 'added', label: 'Added', tooltip: 'Any record that has been added to your database.' },
    { value: 'updated', label: 'Updated', tooltip: 'Any record that has been updated in your database.' },
    { value: 'removed', label: 'Flagged for Removal', tooltip: 'Any record that has been flagged for removal.' },
  ]);
  const [dateRangeOptions] = useState<{ value: string, label: string }[]>([
    { value: 'Last 7 Days', label: 'Last 7 Days' },
    { value: 'Last 30 Days', label: 'Last 30 Days' },
    { value: 'Last 60 Days', label: 'Last 60 Days' },
    { value: 'Custom', label: 'Custom' },
  ]);
  const cubejsApiWrapper = useCubejsApiWrapper();

  useEffect(() => {
    if (organization) {
      setSegments([]);
      setReportName('');
      setSegmentSelected('');
      setSelectedStatus([]);
      setSelectedTable('');
      setDateRangeValue('');
      setTable(null);
      setFromDate(null);
      setToDate(null);
      setTotalCount(0);
      setShowReportData(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  const getPeopleExportQuery = (
    columns: string[], filters: (AudienceFilter | CombinedAudienceFilter)[], dateRange: string[], includesRemoved: boolean
  ): Query => {
    const removedCube = includesRemoved ? '_REMOVED' : '';
    return {
      dimensions: columns.map((item: string) => item.replace('TAM', `TAM${removedCube}_REPORT`).replace('COMPANY', `COMPANY${removedCube}_REPORT`)),
      order: [[`TAM${removedCube}_REPORT.name_per`, 'asc']],
      limit: 50000,
      offset: 0,
      filters: modifyFilters(filters as AudienceFilter[], removedCube),
      timeDimensions: [
        {
          dimension: `TAM${removedCube}_REPORT.${includesRemoved ? '_effective_to' : '_effective_from'}`,
          dateRange: dateRange as DateRange,
          granularity: 'month'
        },
      ],
      segments: [`TAM${removedCube}_REPORT.last_modification`]
    };
  };

  const getCompanyExportQuery = (
    columns: string[], filters: (AudienceFilter | CombinedAudienceFilter)[], dateRange: string[], includesRemoved: boolean
  ): Query => {
    const removedCube = includesRemoved ? '_REMOVED' : '';
    return {
      dimensions: columns.map((item: string) => item.replace('COMPANY', `COMPANY${removedCube}_REPORT`)),
      order: [[`COMPANY${removedCube}_REPORT.domain_org`, 'asc']],
      limit: 50000,
      offset: 0,
      filters: modifyFilters(filters as AudienceFilter[], removedCube),
      timeDimensions: [
        {
          dimension: `COMPANY${removedCube}_REPORT.${includesRemoved ? '_effective_to' : '_effective_from'}`,
          dateRange: dateRange as DateRange,
          granularity: 'month'
        },
      ],
      segments: [`COMPANY${removedCube}_REPORT.last_modification`]
    };
  };

  const getPeopleQuery = (
    pageNo: number, pageSizeNo: number, sortColumn: string, sortValue: 'asc' | 'desc',
    filters: (AudienceFilter | CombinedAudienceFilter)[], additionalColumns: boolean, dateRange: string[], includesRemoved: boolean
  ): Query => {
    const removedCube = includesRemoved ? '_REMOVED' : '';
    const extraColumns: string[] = additionalColumns === true ? [
      `TAM${removedCube}_REPORT.first_name_per`, `TAM${removedCube}_REPORT.last_name_per`, `TAM${removedCube}_REPORT.rbid_per`,
      `TAM${removedCube}_REPORT.rbid_org`,
      ...companyColumns.map((item: GridColDef) => item.field.replace('COMPANY', `COMPANY${removedCube}_REPORT`))
    ] : [];
    const scdColumns = [
      `TAM${removedCube}_REPORT.linkedin_url_per`, `TAM${removedCube}_REPORT.rbid_pao`, `TAM${removedCube}_REPORT._last_change`,
      `TAM${removedCube}_REPORT._effective_from`, `TAM${removedCube}_REPORT._effective_from`, `TAM${removedCube}_REPORT._added`,
      `TAM${removedCube}_REPORT._updated`, `TAM${removedCube}_REPORT._removed`
    ];
    return {
      dimensions: [
        ...new Set([
          ...peopleColumns.map(
            (column: GridColDef) => column.field.replace('TAM', `TAM${removedCube}_REPORT`).replace('COMPANY', `COMPANY${removedCube}_REPORT`)
          ),
          `COMPANY${removedCube}_REPORT.website_org`, `COMPANY${removedCube}_REPORT.linkedin_url_org`,
          ...scdColumns,
          ...extraColumns,
          `TAM${removedCube}_REPORT.lead_source_per`,
          `TAM${removedCube}_REPORT.status_per`
        ])
      ],
      order: [[sortColumn.replace('TAM', `TAM${removedCube}`), sortValue]],
      limit: pageSizeNo,
      offset: pageNo * pageSizeNo,
      filters: modifyFilters(filters as AudienceFilter[], removedCube),
      timeDimensions: [
        {
          dimension: `TAM${removedCube}_REPORT.${includesRemoved ? '_effective_to' : '_effective_from'}`,
          dateRange: dateRange as DateRange,
          granularity: 'month'
        },
      ],
      segments: [`TAM${removedCube}_REPORT.last_modification`]
    };
  };

  const getCompanyQuery = (
    pageNo: number, pageSizeNo: number, sortColumn: string, sortValue: 'asc' | 'desc',
    filters: (AudienceFilter | CombinedAudienceFilter)[], additionalColumns: boolean, dateRange: string[], includesRemoved: boolean
  ): Query => {
    const removedCube = includesRemoved ? '_REMOVED' : '';
    const extraColumns: string[] = additionalColumns === true ? [`COMPANY${removedCube}_REPORT.company_name_org`] : [];
    const scdColumns = [
      `COMPANY${removedCube}_REPORT.rbid_org`, `COMPANY${removedCube}_REPORT._last_change`, `COMPANY${removedCube}_REPORT._effective_from`,
      `COMPANY${removedCube}_REPORT._effective_to`, `COMPANY${removedCube}_REPORT._added`, `COMPANY${removedCube}_REPORT._updated`,
      `COMPANY${removedCube}_REPORT._removed`
    ];
    return {
      dimensions: [
        ...new Set([
          ...companyColumns.map((column: GridColDef) => column.field.replace('COMPANY', `COMPANY${removedCube}_REPORT`)),
          ...scdColumns,
          ...extraColumns,
          `COMPANY${removedCube}_REPORT.linkedin_url_org`,
          `COMPANY${removedCube}_REPORT.website_org`,
          `COMPANY${removedCube}_REPORT.lead_source_org`,
          `COMPANY${removedCube}_REPORT.status_org`
        ])
      ],
      order: [[sortColumn.replace('COMPANY', `COMPANY${removedCube}`), sortValue]],
      limit: pageSizeNo,
      offset: pageNo * pageSizeNo,
      filters: modifyFilters(filters as AudienceFilter[], removedCube),
      timeDimensions: [
        {
          dimension: `COMPANY${removedCube}_REPORT.${includesRemoved ? '_effective_to' : '_effective_from'}`,
          dateRange: dateRange as DateRange,
          granularity: 'month'
        },
      ],
      segments: [`COMPANY${removedCube}_REPORT.last_modification`]
    };
  };

  const handleGetCompareData = async (
    setData: (value: any[]) => void, tableName: string, tableId: string, columns: GridColDef[],
    pageSize: number, dates: DateRange, data: any[]
  ) => {
    try {
      const query: Query = {
        dimensions: [
          ...columns
            .map((column: GridColDef) => column.field.replace('old_', '')),
          `${tableName.toUpperCase()}_REPORT.${tableId}`, `${tableName.toUpperCase()}_REPORT._last_change`,
          `${tableName.toUpperCase()}_REPORT._effective_from`
        ],
        limit: pageSize,
        offset: 0,
        filters: [
          {
            member: `${tableName.toUpperCase()}_REPORT.${tableId}`,
            operator: 'equals',
            values: data
              .filter((item: any) => item[`${tableName.toUpperCase()}_REPORT.${tableId}`] &&
                item[`${tableName.toUpperCase()}_REPORT._updated`] === true)
              .map((item: any) => item[`${tableName.toUpperCase()}_REPORT.${tableId}`])
          },
        ],
        timeDimensions: [
          {
            dimension: `${tableName.toUpperCase()}_REPORT._effective_from`,
            dateRange: dates as DateRange,
          }
        ],
        segments: [`${tableName.toUpperCase()}_REPORT.last_modification`]
      };
      const resultSet: ResultSet<any> = await cubejsApiWrapper(query, { mutexKey: 'report-compare-data' });
      setData(resultSet.tablePivot());
    } catch (error) {
      setData([]);
    }
  };

  const removedReportCondition = (status: string, tableName: string) => {
    return status !== 'removed' ? [{ member: `${tableName.toUpperCase()}._removed`, operator: 'equals', values: ['0'] }] : [];
  };

  const buildReportFilter = (tableName: string, statuses: string[]) => {
    return statuses.map((value: string) => ({
      and: [
        { member: `${tableName.toUpperCase()}._${value}`, operator: 'equals', values: ['1'] },
        ...removedReportCondition(value, tableName)
      ]
    }));
  };

  const buildDateRange = (dateRangeValue: string, fromDate: Date | null, toDate: Date | null): string[] => {
    if (dateRangeValue === 'Custom' && fromDate && toDate) {
      return dateArray(fromDate, toDate);
    } else {
      const days = +(dateRangeValue.split(' ')[1]);
      const today = new Date(Date.now());
      const startDate = subDays(today, days);
      return dateArray(startDate, today);
    }
  };

  const dateArray = (startDateValue: Date, endDateValue: Date): string[] => {
    const startDate = format(startDateValue, 'yyyy-MM-dd');
    const endDate = format(endDateValue, 'yyyy-MM-dd');
    return [startDate, endDate];
  };

  const modifyFilters = (filters: AudienceFilter[], removedCube: string): AudienceFilter[] => {
    return filters.map((item: any) => {
      if (item.member) {
        const tamName = item.member.split('.')[0];
        return { ...item, member: item.member.replace(tamName, `${tamName}${removedCube}_REPORT`) };
      } else if (item.or && item.or.length) {
        if (item.or[0].member) {
          const tamName = item.or[0].member.split('.')[0];
          return {
            ...item,
            or: [
              {
                ...item.or[0],
                member: item.or[0].member.replace(tamName, `${tamName}${removedCube}_REPORT`)
              }
            ]
          };
        } else if (item.or[0].and && item.or[0].and.length) {
          const tamName = item.or[0].and[0].member.split('.')[0];
          return {
            ...item,
            or: item.or.map((value: any) => ({
              ...value,
              and: value.and.map((filter: any) => ({ ...filter, member: filter.member.replace(tamName, `${tamName}${removedCube}_REPORT`) }))
            }))
          };
        }
      }
    });
  };

  return (
    <ReportContext.Provider
      value={{
        reportName,
        setReportName,
        segmentSelected,
        setSegmentSelected,
        segments,
        setSegments,
        selectedStatus,
        setSelectedStatus,
        selectedTable,
        setSelectedTable,
        table,
        setTable,
        dateRangeValue,
        setDateRangeValue,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        reportSegmentFilters,
        setReportSegmentFilters,
        statuses,
        dateRangeOptions,
        runReport,
        setRunReport,
        showReportData,
        setShowReportData,
        reportData,
        setReportData,
        getPeopleQuery,
        getCompanyQuery,
        totalCount,
        setTotalCount,
        updatedStatusAdded,
        setUpdatedStatusAdded,
        compareData,
        setCompareData,
        compareValues,
        setCompareValues,
        handleGetCompareData,
        getPeopleExportQuery,
        getCompanyExportQuery,
        buildReportFilter,
        buildDateRange,
        dateArray,
        loadingData,
        setLoadingData,
        modifyFilters,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};
