import '../styles/globals.scss';
import type { AppProps } from 'next/app';
import { useEffect } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { GlobalProvider } from '../context/globalState';
import { UIProvider } from '../context/uiState';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { theme } from '../styles/theme';
import { CubeJSProvider } from '../context/cubeState';
import { ExportProvider } from '../context/exportState';
import { BasicFiltersProvider } from '../context/basicFiltersState';
import { NewSegmentProvider } from '../context/newSegmentState';
import { ReportProvider } from '../context/reportState';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import TagManager from 'react-gtm-module';
import Maintenance from './maintenance';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';


LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_KEY as string);

function MyApp ({ Component, pageProps }: AppProps) {

  useEffect(() => {
    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_KEY as string });
  }, []);

  if (process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true') {
    return <Maintenance />;
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Auth0Provider
          domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN as string}
          clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID as string}
          redirectUri={typeof window !== 'undefined' ? window.location.origin : 'http://localhost:3000'}
          audience={process.env.NEXT_PUBLIC_AUTH0_AUDIENCE as string}
        >
          <PostHogProvider client={posthog}>
            <UIProvider>
              <GlobalProvider>
                <BasicFiltersProvider>
                  <NewSegmentProvider>
                    <CubeJSProvider>
                      <ReportProvider>
                        <ExportProvider>
                          <Component {...pageProps} />
                        </ExportProvider>
                      </ReportProvider>
                    </CubeJSProvider>
                  </NewSegmentProvider>
                </BasicFiltersProvider>
              </GlobalProvider>
            </UIProvider>
          </PostHogProvider>
        </Auth0Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default MyApp;