import type { NextPage } from 'next';
import { useContext, createContext, useState, useEffect } from 'react';
import { useCubeContext } from './cubeState';
import { useGlobalState } from './globalState';
import { useUiState } from './uiState';
import { useAuth0 } from '@auth0/auth0-react';
import { useReportState } from './reportState';
import { useBasicFiltersState } from './basicFiltersState';
import { AudienceFilter, CombinedAudienceFilter } from '../types/Cube.interfaces';
import { GridColDef } from '@mui/x-data-grid';
import { ResultSet, SqlQuery, Query, DateRange } from '@cubejs-client/core';
import { ExportRecordForPostHog, ExportHistory, TableColumn } from '../types/Common.interfaces';
import axios, { AxiosResponse } from 'axios';
import useCubejsApiWrapper from '../apiHelpers/cubejsWrapper';

type ExportProps = {
  handleDownloadData: (
    setOpenModal: (value: boolean) => void, name: string, columns: GridColDef[], filters: (AudienceFilter | CombinedAudienceFilter)[],
    table: string, setError: (value: boolean) => void, id: string
  ) => Promise<void>;
  cancelExport: () => void;
  requestedExports: any[];
  handleDownloadReportData: (
    setOpenModal: (value: boolean) => void, name: string, columns: GridColDef[], filters: (AudienceFilter | CombinedAudienceFilter)[],
    table: string, setError: (value: boolean) => void, id: string, dateRange: string[], includesRemoved: boolean, multipleStatuses?: boolean
  ) => Promise<void>;
}

const DEFAULT_PROPS = {
  handleDownloadData: (
    setOpenModal: (value: boolean) => void, name: string, columns: GridColDef[], filters: (AudienceFilter | CombinedAudienceFilter)[],
    table: string, setError: (value: boolean) => void, id: string
  ) => new Promise<void>((resolve, reject) => resolve()),
  cancelExport: () => {},
  requestedExports: [],
  handleDownloadReportData: (
    setOpenModal: (value: boolean) => void, name: string, columns: GridColDef[], filters: (AudienceFilter | CombinedAudienceFilter)[],
    table: string, setError: (value: boolean) => void, id: string, dateRange: string[], includesRemoved: boolean, multipleStatuses?: boolean
  ) => new Promise<void>((resolve, reject) => resolve()),
};

export const ExportContext = createContext<ExportProps>(DEFAULT_PROPS);

export const useExportState = () => useContext(ExportContext);

export const ExportProvider: NextPage = ({ children }) => {
  const { user } = useAuth0();
  const {
    apiHeaders,
    organization,
    organizationData,
    userMetadata,
    activeDataset,
    companyDatabaseColumns,
    peopleDatabaseColumns,
    captureEventInPostHog,
    trialAccount,
  } = useGlobalState();
  const { cubejsApi } = useCubeContext();
  const { setIsExportOpen, displayToast } = useUiState();
  const { getPeopleExportQuery, getCompanyExportQuery, modifyFilters } = useReportState();
  const { modifyJobTitleFilter } = useBasicFiltersState();
  const [requestedExports, setRequestedExports] = useState<{ name: string, id: string, progress: number }[]>([]);
  const [lastUpdatedExport, setLastUpdatedExport] = useState<{ id: string, progress: number } | null>(null);
  const [controller] = useState<AbortController>(new AbortController());

  const [companyColumnsSortOrder] = useState<{ [key: string]: number }>({
    'COMPANY.company_name_org': 300, 'COMPANY.linkedin_industry_org': 290, 'COMPANY.domain_org': 285, 'COMPANY.revenue_range_org': 280,
    'COMPANY.employee_count_range_org': 270, 'COMPANY.phone_org': 260, 'COMPANY.street_org': 250, 'COMPANY.city_org': 240,
    'COMPANY.state_name_org': 230, 'COMPANY.postcode_org': 220, 'COMPANY.country_name_org': 210, 'COMPANY.industry_sic_description_org': 200,
    'COMPANY.industry_sic_code_org': 195, 'COMPANY.industry_naics_description_org': 190, 'COMPANY.industry_naics_code_org': 185,
    'COMPANY.status_org': 170
  });
  const [peopleColumnsSortOrder] = useState<{ [key: string]: number }>({
    'COMPANY.company_name_org': 300, 'COMPANY.revenue_range_org': 290, 'COMPANY.employee_count_range_org': 280, 'COMPANY.phone_org': 270,
    'COMPANY.street_org': 260, 'COMPANY.city_org': 250, 'COMPANY.state_name_org': 240, 'COMPANY.postcode_org': 230, 'COMPANY.country_name_org': 220,
    'COMPANY.industry_sic_description_org': 210, 'COMPANY.industry_sic_code_org': 205, 'COMPANY.industry_naics_code_org': 195,
    'COMPANY.industry_naics_description_org': 200, 'COMPANY.linkedin_industry_org': 190, 'TAM.name_per': 160, 'TAM.direct_phone_per': 120,
    'TAM.job_title_per': 150, 'TAM.job_level_per': 140, 'TAM.email_address_per': 110, 'TAM.email_status_per': 100, 'TAM.cellphone_per': 130,
    'TAM.city_per': 80, 'TAM.state_name_per': 70, 'TAM.country_name_per': 50, 'TAM.status_per': 20
  });
  const [webSockets, setWebSockets] = useState<{ socket: WebSocket, id: string }[]>([]);
  const cubejsApiWrapper = useCubejsApiWrapper();

  useEffect(() => {
    if (lastUpdatedExport) {
      const findExport = requestedExports.find((item: { name: string, id: string, progress: number }) => item.id === lastUpdatedExport.id);
      if (findExport) {
        if (lastUpdatedExport.progress !== 1) {
          findExport.progress = lastUpdatedExport.progress;
          setRequestedExports([...requestedExports]);
        } else {
          setRequestedExports(requestedExports.filter((item: { name: string, id: string, progress: number }) => item.id !== lastUpdatedExport.id));
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdatedExport]);

  useEffect(() => {
    if (!requestedExports.length) {
      setIsExportOpen(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestedExports]);

  const availableExport = async (): Promise<boolean> => {
    if (organizationData && activeDataset) {
      if (trialAccount) {
        try {
          const response: AxiosResponse<{ totalCount: number }> = await axios.get(
            `/api/clients/${organizationData.id}/datasets/${activeDataset.id}/export-history/count`,
            { ...apiHeaders, signal: controller.signal }
          );
          return response.data.totalCount < 15;
        } catch (error) {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const getQuery = (filters: (AudienceFilter | CombinedAudienceFilter)[], columns: string[]): Query => {
    return {
      dimensions: [...new Set(columns)],
      order: [['TAM.name_per', 'asc']],
      offset: 0,
      limit: 50000,
      filters: modifyJobTitleFilter(filters),
    };
  };

  const getCompanyQuery = (filters: (AudienceFilter | CombinedAudienceFilter)[], columns: string[]): Query => {
    return {
      dimensions: [...new Set(columns)],
      order: [['COMPANY.domain_org', 'asc']],
      offset: 0,
      limit: 50000,
      filters: modifyJobTitleFilter(filters),
    };
  };

  const handleDownloadData = async (
    setOpen: (value: boolean) => void, name: string, columns: GridColDef[], filters: (AudienceFilter | CombinedAudienceFilter)[],
    table: string, setError: (value: boolean) => void, id: string
  ) => {
    try {
      setOpen(false);
      const exportAvailable = await availableExport();
      if (!exportAvailable) {
        displayToast(
          // eslint-disable-next-line max-len
          'The data export limit included in the free trial has been reached. For additional exports, or to move to a paid account, please contact your Sales or Customer Success representative.',
          'error'
        );
      } else {
        const newExports = [...requestedExports];
        newExports.push({ name: name, id: id, progress: 0 });
        setRequestedExports(newExports);
        setIsExportOpen(true);
        const fileHeaders: string[] = [];
        const mappings: { columnName: string, exportName: string }[] = [];
        let query: Query = {};
        if (table === 'people') {
          fileHeaders.push('TAM.rbid_pao', 'TAM.rbid_org');
          mappings.push({
            columnName: 't_a_m__rbid_pao',
            exportName: 'rbid_pao'
          }, {
            columnName: 't_a_m__rbid_org',
            exportName: 'rbid_org'
          });
          columns
            .sort((aColumn: GridColDef, bColumn: GridColDef) => {
              const bValue = peopleColumnsSortOrder[bColumn.field] || 20;
              const aValue = peopleColumnsSortOrder[aColumn.field] || 20;
              return bValue - aValue;
            })
            .forEach((column: GridColDef) => {
              if (column.field.split('.')[1] === 'name') {
                fileHeaders.push('COMPANY.company_name_org', 'COMPANY.domain_org');
              } else if (column.field.split('.')[1] === 'name_per') {
                fileHeaders.push('TAM.first_name_per', 'TAM.last_name_per', 'TAM.linkedin_url_per');
              } else {
                fileHeaders.push(column.field);
              }
            });
          peopleDatabaseColumns.forEach((column: TableColumn) => {
            if (column.columnName === 'name') {
              mappings.push({
                columnName: 'c_o_m_p_a_n_y__company_name_org',
                exportName: column.exportName ? column.exportName : 'company_name_org',
              }, {
                columnName: 'c_o_m_p_a_n_y__domain_org',
                exportName: 'Company domain name',
              });
            } else if (column.columnName === 'name_per') {
              mappings.push({
                columnName: 't_a_m__first_name_per',
                exportName: 'First name',
              }, {
                columnName: 't_a_m__last_name_per',
                exportName: 'Last name',
              }, {
                columnName: 't_a_m__linkedin_url_per',
                exportName: 'LinkedIn profile URL',
              });
            } else {
              mappings.push({
                columnName: `t_a_m__${column.columnName}`,
                exportName: column.exportName ? column.exportName : column.columnName
              });
            }
          });
          companyDatabaseColumns.forEach((column: TableColumn) => {
            if (column.columnName !== 'name') {
              mappings.push({
                columnName: `c_o_m_p_a_n_y__${column.columnName}`,
                exportName: column.exportName ? column.exportName : column.columnName
              });
            }
          });
          fileHeaders.push('TAM.lead_source_per');
          mappings.push({
            columnName: 't_a_m__lead_source_per',
            exportName: 'lead_source_per'
          });
          query = getQuery(filters, fileHeaders);
        } else if (table === 'company') {
          fileHeaders.push('COMPANY.rbid_org');
          mappings.push({
            columnName: 'c_o_m_p_a_n_y__rbid_org',
            exportName: 'rbid_org'
          });
          columns
            .sort((aColumn: GridColDef, bColumn: GridColDef) => {
              const bValue = companyColumnsSortOrder[bColumn.field] || 20;
              const aValue = companyColumnsSortOrder[aColumn.field] || 20;
              return bValue - aValue;
            })
            .forEach((column: GridColDef) => {
              if (column.field.split('.')[1] === 'name') {
                fileHeaders.push('COMPANY.company_name_org');
              } else {
                fileHeaders.push(column.field);
              }
            });
          companyDatabaseColumns.forEach((column: TableColumn) => {
            if (column.columnName === 'name') {
              mappings.push({
                columnName: 'c_o_m_p_a_n_y__company_name_org',
                exportName: column.exportName ? column.exportName : 'company_name_org'
              });
            } else {
              mappings.push({
                columnName: `c_o_m_p_a_n_y__${column.columnName}`,
                exportName: column.exportName ? column.exportName : column.columnName
              });
            }
          });
          fileHeaders.push('COMPANY.lead_source_org');
          mappings.push({
            columnName: 'c_o_m_p_a_n_y__lead_source_org',
            exportName: 'lead_source_org'
          });
          query = getCompanyQuery(filters, fileHeaders);
        }
        const queryResult: SqlQuery = await cubejsApi.sql(query);
        const resultSet: ResultSet<any> = await cubejsApiWrapper({
          measures: [table === 'people' ? 'TAM.countDistinct' : 'COMPANY.countDistinct'],
          filters: modifyJobTitleFilter(filters),
        });
        const totalCount = resultSet.tablePivot()[0][table === 'people' ? 'TAM.countDistinct' : 'COMPANY.countDistinct'];
        if (organization && activeDataset && user && apiHeaders) {
          const body = {
            query: queryResult.rawQuery().sql,
            name: name,
            totalCount,
            organization: organization.name,
            table,
            mappings,
            trialAccount,
          };
          const socket = new WebSocket(`${process.env.NEXT_PUBLIC_WEBSOCKET_API as string}?token=${apiHeaders.headers.Authorization}`);
          setWebSockets([...webSockets, { id: id, socket: socket }]);
          socket.addEventListener('open', (event: any) => {
            handleExport(socket, body, 'export');
          });
          socket.addEventListener('close', (event: any) => {
            setIsExportOpen(false);
            setRequestedExports(requestedExports.filter((value: { name: string, id: string, progress: number }) => value.id !== id));
          });
          socket.addEventListener('error', (event: any) => {
            setIsExportOpen(false);
            setRequestedExports(requestedExports.filter((value: { name: string, id: string, progress: number }) => value.id !== id));
            socket.close();
          });
          socket.addEventListener('message', (event: any) => {
            const data = JSON.parse(event.data);
            if (data.url) {
              window.location.href = data.url;
              const exportedRecords: ExportRecordForPostHog = {
                name: table === 'people' ? 'People count' : 'Company count',
                count: totalCount,
              };
              captureEventInPostHog('Export', activeDataset.productName, exportedRecords);
              handleCreateExportRecord(name);
            } else if (data.progress) {
              setLastUpdatedExport({ id: id, progress: +data.progress });
            } else if (data.message && data.message.toLowerCase().includes('error')) {
              setIsExportOpen(false);
              setError(true);
              setRequestedExports(requestedExports.filter((value: { name: string, id: string, progress: number }) => value.id !== id));
              socket.close();
              captureEventInPostHog('Export failed', activeDataset.productName);
            }
          });
        }
      }
    } catch (error) {
      setError(true);
      setIsExportOpen(false);
    }
  };

  const handleDownloadReportData = async (
    setOpen: (value: boolean) => void, name: string, columns: GridColDef[], filters: (AudienceFilter | CombinedAudienceFilter)[],
    table: string, setError: (value: boolean) => void, id: string, dateRange: string[], includesRemoved: boolean, multipleStatuses?: boolean
  ) => {
    try {
      setOpen(false);
      const exportAvailable = await availableExport();
      if (!exportAvailable) {
        displayToast(
          // eslint-disable-next-line max-len
          'The data export limit included in the free trial has been reached. For additional exports, or to move to a paid account, please contact your Sales or Customer Success representative.',
          'error'
        );
      } else {
        const newExports = [...requestedExports];
        newExports.push({ name: name, id: id, progress: 0 });
        setRequestedExports(newExports);
        setIsExportOpen(true);
        const removedMapping = includesRemoved ? '__r_e_m_o_v_e_d' : '';
        const fileHeaders: string[] = [];
        const mappings: { columnName: string, exportName: string }[] = [];
        let query: Query = {};
        const modifiedFilters = modifyJobTitleFilter(filters);
        if (table === 'people') {
          fileHeaders.push('TAM.rbid_pao', 'TAM.rbid_org');
          mappings.push({
            columnName: `t_a_m${removedMapping}__r_e_p_o_r_t__rbid_pao`,
            exportName: 'rbid_pao'
          }, {
            columnName: `t_a_m${removedMapping}__r_e_p_o_r_t__rbid_org`,
            exportName: 'rbid_org'
          });
          columns
            .sort((aColumn: GridColDef, bColumn: GridColDef) => {
              const bValue = peopleColumnsSortOrder[bColumn.field] || 20;
              const aValue = peopleColumnsSortOrder[aColumn.field] || 20;
              return bValue - aValue;
            })
            .forEach((column: GridColDef) => {
              if (column.field.split('.')[1] === 'name') {
                fileHeaders.push('COMPANY.company_name_org', 'COMPANY.domain_org');
              } else if (column.field.split('.')[1] === 'name_per') {
                fileHeaders.push('TAM.first_name_per', 'TAM.last_name_per', 'TAM.linkedin_url_per');
              } else {
                fileHeaders.push(column.field);
              }
            });
          peopleDatabaseColumns.forEach((column: TableColumn) => {
            if (column.columnName === 'name') {
              mappings.push({
                columnName: `c_o_m_p_a_n_y${removedMapping}__r_e_p_o_r_t__company_name_org`,
                exportName: column.exportName ? column.exportName : 'company_name_org',
              }, {
                columnName: `c_o_m_p_a_n_y${removedMapping}__r_e_p_o_r_tt__domain_org`,
                exportName: 'Company domain name',
              });
            } else if (column.columnName === 'name_per') {
              mappings.push({
                columnName: `t_a_m${removedMapping}__r_e_p_o_r_t__first_name_per`,
                exportName: 'First name',
              }, {
                columnName: `t_a_m_${removedMapping}_r_e_p_o_r_t__last_name_per`,
                exportName: 'Last name',
              }, {
                columnName: `t_a_m${removedMapping}__r_e_p_o_r_t__linkedin_url_per`,
                exportName: 'LinkedIn profile URL',
              });
            } else {
              mappings.push({
                columnName: `t_a_m${removedMapping}__r_e_p_o_r_t__${column.columnName}`,
                exportName: column.exportName ? column.exportName : column.columnName
              });
            }
          });
          companyDatabaseColumns.forEach((column: TableColumn) => {
            if (column.columnName !== 'name') {
              mappings.push({
                columnName: `c_o_m_p_a_n_y${removedMapping}__r_e_p_o_r_t__${column.columnName}`,
                exportName: column.exportName ? column.exportName : column.columnName
              });
            }
          });
          if (multipleStatuses) {
            fileHeaders.push('TAM.status_per');
            mappings.push({
              columnName: `t_a_m${removedMapping}__r_e_p_o_r_t__status_per`,
              exportName: 'status'
            });
          }
          fileHeaders.push('TAM.lead_source_per');
          mappings.push({
            columnName: `t_a_m${removedMapping}__r_e_p_o_r_t__lead_source_per`,
            exportName: 'lead_source_per'
          });
          query = getPeopleExportQuery(fileHeaders, modifiedFilters, dateRange, includesRemoved);
        } else if (table === 'company') {
          fileHeaders.push('COMPANY.rbid_org');
          mappings.push({
            columnName: `c_o_m_p_a_n_y${removedMapping}__r_e_p_o_r_t__rbid_org`,
            exportName: 'rbid_org'
          });
          columns
            .sort((aColumn: GridColDef, bColumn: GridColDef) => {
              const bValue = companyColumnsSortOrder[bColumn.field] || 20;
              const aValue = companyColumnsSortOrder[aColumn.field] || 20;
              return bValue - aValue;
            })
            .forEach((column: GridColDef) => {
              if (column.field.split('.')[1] === 'name') {
                fileHeaders.push('COMPANY.company_name_org');
              } else {
                fileHeaders.push(column.field);
              }
            });
          companyDatabaseColumns.forEach((column: TableColumn) => {
            if (column.columnName === 'name') {
              mappings.push({
                columnName: `c_o_m_p_a_n_y${removedMapping}__r_e_p_o_r_t__company_name_org`,
                exportName: column.exportName ? column.exportName : 'company_name_org'
              });
            } else {
              mappings.push({
                columnName: `c_o_m_p_a_n_y${removedMapping}__r_e_p_o_r_t__${column.columnName}`,
                exportName: column.exportName ? column.exportName : column.columnName
              });
            }
          });
          if (multipleStatuses) {
            fileHeaders.push('COMPANY.status_org');
            mappings.push({
              columnName: `c_o_m_p_a_n_y${removedMapping}__r_e_p_o_r_t__status_org`,
              exportName: 'status'
            });
          }
          fileHeaders.push('COMPANY.lead_source_org');
          mappings.push({
            columnName: `c_o_m_p_a_n_y${removedMapping}__r_e_p_o_r_t__lead_source_org`,
            exportName: 'lead_source_org'
          });
          query = getCompanyExportQuery(fileHeaders, modifiedFilters, dateRange, includesRemoved);
        }
        const queryResult: SqlQuery = await cubejsApi.sql(query);
        const removedCube = includesRemoved ? '_REMOVED' : '';
        const peopleTableExport = table === 'people';
        const cubeTableName = peopleTableExport ? `TAM${removedCube}_REPORT` : `COMPANY${removedCube}_REPORT`;
        const resultSet: ResultSet<any> = await cubejsApiWrapper({
          measures: [`${cubeTableName}.countDistinct`],
          filters: modifyFilters(modifiedFilters as AudienceFilter[], removedCube),
          timeDimensions: [
            {
              dimension: `${cubeTableName}.${includesRemoved ? '_effective_to' : '_effective_from'}`,
              dateRange: dateRange as DateRange,
            },
          ],
          segments: [`${cubeTableName}.last_modification`]
        });
        const totalCount = resultSet.tablePivot()[0][`${cubeTableName}.countDistinct`];
        if (organization && activeDataset && user && apiHeaders) {
          const body = {
            query: queryResult.rawQuery().sql,
            name: name,
            totalCount,
            organization: organization.name,
            table,
            mappings,
            reportExport: true,
            removedReportExport: includesRemoved,
            trialAccount,
          };
          const socket = new WebSocket(`${process.env.NEXT_PUBLIC_WEBSOCKET_API as string}?token=${apiHeaders.headers.Authorization}`);
          setWebSockets([...webSockets, { id: id, socket: socket }]);
          socket.addEventListener('open', (event: any) => {
            handleExport(socket, body, 'export');
          });
          socket.addEventListener('close', (event: any) => {
            setIsExportOpen(false);
            setRequestedExports(requestedExports.filter((value: { name: string, id: string, progress: number }) => value.id !== id));
          });
          socket.addEventListener('error', (event: any) => {
            setIsExportOpen(false);
            setRequestedExports(requestedExports.filter((value: { name: string, id: string, progress: number }) => value.id !== id));
            socket.close();
          });
          socket.addEventListener('message', (event: any) => {
            const data = JSON.parse(event.data);
            if (data.url) {
              window.location.href = data.url;
              const exportedRecords: ExportRecordForPostHog = {
                name: table === 'people' ? 'People count' : 'Company count',
                count: totalCount,
              };
              captureEventInPostHog('Export', activeDataset.productName, exportedRecords);
              handleCreateExportRecord(name);
            } else if (data.progress) {
              setLastUpdatedExport({ id: id, progress: +data.progress });
            } else if (data.message && data.message.toLowerCase().includes('error')) {
              setIsExportOpen(false);
              setError(true);
              setRequestedExports(requestedExports.filter((value: { name: string, id: string, progress: number }) => value.id !== id));
              socket.close();
              captureEventInPostHog('Export failed', activeDataset.productName);
            }
          });
        }
      }
    } catch (error) {
      setError(true);
      setIsExportOpen(false);
    }
  };

  const handleExport = (socket: WebSocket, body: any, action: string) => {
    const data = {
      action: action,
      body,
    };
    socket.send(JSON.stringify(data));
  };

  const cancelExport = () => {
    if (webSockets.length) {
      webSockets.forEach((item: { id: string, socket: WebSocket }) => {
        item.socket.close();
      });
    }
    setRequestedExports([]);
    setWebSockets([]);
  };

  const handleCreateExportRecord = async (name: string) => {
    if (organizationData && activeDataset && user) {
      try {
        const body = {
          exportName: name,
          datasetId: activeDataset.id,
          exportedAt: new Date(),
          user: {
            firstName: user[userMetadata].first_name || '',
            lastName: user[userMetadata].last_name || '',
            email: user.email || '',
            orgId: organizationData.id,
            userId: user.sub,
          },
        };
        // eslint-disable-next-line no-unused-vars
        const response: AxiosResponse<ExportHistory> = await axios.post(
          `/api/clients/${organizationData.id}/datasets/${activeDataset.id}/export-history`,
          JSON.stringify(body),
          { ...apiHeaders, signal: controller.signal }
        );
        displayToast('Your export has been created!', 'success');
      } catch (error) {
        //setError(true);
      }
    }
  };

  return (
    <ExportContext.Provider
      value={{
        handleDownloadData,
        cancelExport,
        requestedExports,
        handleDownloadReportData
      }}
    >
      {children}
    </ExportContext.Provider>
  );
};
