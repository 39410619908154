import { UnaryFilter, Query } from '@cubejs-client/core';
import type { NextPage } from 'next';
import { useState, createContext, useContext } from 'react';
import { AudienceFilter, CombinedAudienceFilter } from '../types/Cube.interfaces';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useGlobalState } from './globalState';

type GlobalProps = {
  newSegmentFilters: AudienceFilter[];
  setNewSegmentFilters: (value: AudienceFilter[]) => void;
  handleAddFilter: (
    value: AudienceFilter | UnaryFilter, filters: AudienceFilter[], setFilters: (value: AudienceFilter[]) => void, addAsNew?: boolean
  ) => void;
  handleAddNumericFilter: (value: AudienceFilter | UnaryFilter, filters: AudienceFilter[], setFilters: (value: AudienceFilter[]) => void) => void;
  handleCombinedNumericFilter: (
    currentFilterName: string, currentFilter: CombinedAudienceFilter, filters: AudienceFilter[] | CombinedAudienceFilter[],
    setFilters: (value: any[]) => void
  ) => void;
  modifyFilters: (value: AudienceFilter[], dontModify?: boolean) => AudienceFilter[];
  handleRemoveAudienceFilter: (value: AudienceFilter | UnaryFilter, filters: AudienceFilter[], setFilters: (value: AudienceFilter[]) => void) => void;
  handleRemoveFilterValue: (
    value: AudienceFilter | UnaryFilter, newValues: any[], filters: AudienceFilter[], setFilters: (value: AudienceFilter[]) => void
  ) => void;
  handleAddDateFilter: (value: AudienceFilter | UnaryFilter, filters: AudienceFilter[], setFilters: (value: AudienceFilter[]) => void) => void;
  getQuery: (
    pageNo: number, pageSizeNo: number, sortColumn: string, sortValue: 'asc' | 'desc', filters: AudienceFilter[], additionalColumns?: boolean
  ) => Query;
  getCompanyQuery: (
    pageNo: number, pageSizeNo: number, sortColumn: string, sortValue: 'asc' | 'desc', filters: AudienceFilter[], additionalColumns?: boolean
  ) => Query;
  areObjectSame: (object1: object, object2: object) => boolean;
  modifyJobTitleFilter: (filters: (AudienceFilter | CombinedAudienceFilter)[]) => (AudienceFilter | CombinedAudienceFilter)[];
}

const DEFAULT_PROPS = {
  newSegmentFilters: [],
  setNewSegmentFilters: (value: AudienceFilter[]) => {},
  handleAddFilter: (
    value: AudienceFilter | UnaryFilter, filters: AudienceFilter[], setFilters: (value: AudienceFilter[]) => void, addAsNew?: boolean
  ) => {},
  handleAddNumericFilter: (value: AudienceFilter | UnaryFilter, filters: AudienceFilter[], setFilters: (value: AudienceFilter[]) => void) => {},
  handleCombinedNumericFilter: (
    currentFilterName: string, currentFilter: CombinedAudienceFilter, filters: AudienceFilter[] | CombinedAudienceFilter[],
    setFilters: (value: any[]) => void
  ) => {},
  modifyFilters: (value: AudienceFilter[], dontModify?: boolean) => { return []; },
  handleRemoveAudienceFilter: (value: AudienceFilter | UnaryFilter, filters: AudienceFilter[], setFilters: (value: AudienceFilter[]) => void) => {},
  handleRemoveFilterValue: (
    value: AudienceFilter | UnaryFilter, newValues: any[], filters: AudienceFilter[], setFilters: (value: AudienceFilter[]) => void
  ) => {},
  handleAddDateFilter: (value: AudienceFilter | UnaryFilter, filters: AudienceFilter[], setFilters: (value: AudienceFilter[]) => void) => {},
  getQuery: (
    pageNo: number, pageSizeNo: number, sortColumn: string, sortValue: 'asc' | 'desc', filters: AudienceFilter[], additionalColumns?: boolean
  ) => { return { }; },
  getCompanyQuery: (
    pageNo: number, pageSizeNo: number, sortColumn: string, sortValue: 'asc' | 'desc', filters: AudienceFilter[], additionalColumns?: boolean
  ) => { return { }; },
  areObjectSame: (object1: object, object2: object) => false,
  modifyJobTitleFilter: (filters: (AudienceFilter | CombinedAudienceFilter)[]) => [],
};

export const NewSegmentContext = createContext<GlobalProps>(DEFAULT_PROPS);

export const useNewSegmentState = () => useContext(NewSegmentContext);

export const NewSegmentProvider: NextPage = ({ children }) => {
  const { companyColumns, peopleColumns } = useGlobalState();
  const [newSegmentFilters, setNewSegmentFilters] = useState<any[]>([]);

  const handleAddFilter = (
    filter: AudienceFilter | UnaryFilter, filters: AudienceFilter[], setFilters: (value: AudienceFilter[]) => void, addAsNew?: boolean
  ) => {
    if (filter.values && filter.values.length > 0) {
      const filterExists = filters.find((item: AudienceFilter) => (
        item.member && filter.member && item.member.split('.')[1] === filter.member.split('.')[1] && item.operator === filter.operator
      ));
      if (!filterExists) {
        const newAudienceFilters = [...filters];
        newAudienceFilters.push(filter);
        setFilters(newAudienceFilters);
      } else {
        if (addAsNew) {
          const newAudienceFilters = [...filters];
          newAudienceFilters.push(filter);
          setFilters(newAudienceFilters);
        } else {
          filterExists.values = [...new Set([...filter.values, ...filterExists.values])];
          setFilters([...filters]);
        }
      }
    } else if (filter.values && filter.values.length === 0) {
      const filterIndex = filters.findIndex((item: AudienceFilter) => (
        item.member && filter.member && item.member.split('.')[1] === filter.member.split('.')[1] && item.operator === filter.operator
      ));
      if (filterIndex > -1) {
        filters.splice(filterIndex, 1);
        setFilters([...filters]);
      }
    } else if (!filter.values) {
      const filterIndex = filters.findIndex((item: any) => (
        item.member && filter.member && item.member.split('.')[1] === filter.member.split('.')[1] && item.operator === filter.operator
      ));
      if (filterIndex > -1) {
        filters.splice(filterIndex, 1);
        setFilters([...filters]);
      } else {
        const newAudienceFilters: any[] = [...filters, filter];
        setFilters(newAudienceFilters);
      }
    }
  };

  const handleAddNumericFilter = (
    filter: AudienceFilter | UnaryFilter, filters: AudienceFilter[], setFilters: (value: AudienceFilter[]) => void
  ) => {
    if (filter.values && filter.values.length > 0) {
      const filterExists = filters.find((item: AudienceFilter) => (
        item.member && filter.member && item.member.split('.')[1] === filter.member.split('.')[1] && item.operator === filter.operator
      ));
      if (!filterExists) {
        const newAudienceFilters = [...filters];
        newAudienceFilters.push(filter);
        setFilters(newAudienceFilters);
      } else {
        if (filter.values.length === 1 && !filter.values[0]) {
          const filterIndex = filters.findIndex((item: AudienceFilter) => (
            item.member && filter.member && item.member.split('.')[1] === filter.member.split('.')[1] && item.operator === filter.operator
          ));
          if (filterIndex > -1) {
            filters.splice(filterIndex, 1);
            setFilters([...filters]);
          }
          return;
        }
        filterExists.values = [filter.values[0]];
        setFilters([...filters]);
      }
    } else if (filter.values && filter.values.length === 0) {
      const filterIndex = filters.findIndex((item: AudienceFilter) => (
        item.member && filter.member && item.member.split('.')[1] === filter.member.split('.')[1] && item.operator === filter.operator
      ));
      if (filterIndex > -1) {
        filters.splice(filterIndex, 1);
        setFilters([...filters]);
      }
    } else if (!filter.values) {
      const filterIndex = filters.findIndex((item: any) => (
        item.member && filter.member && item.member.split('.')[1] === filter.member.split('.')[1] && item.operator === filter.operator
      ));
      if (filterIndex > -1) {
        filters.splice(filterIndex, 1);
        setFilters([...filters]);
      } else {
        const newAudienceFilters: any[] = [...filters, filter];
        setFilters(newAudienceFilters);
      }
    }
  };

  const handleCombinedNumericFilter = (
    currentFilterName: string, currentFilter: CombinedAudienceFilter, filters: AudienceFilter[] | CombinedAudienceFilter[],
    setFilters: (value: any) => void
  ) => {
    if (currentFilter && currentFilter.or && currentFilter.or.length > 0) {
      const filterIndex = filters.findIndex((el: any) => {
        return !!el.or && el.or.length &&
        (
          (el.or[0].member && el.or[0].member.split('.')[1] === currentFilterName.split('.')[1]) ||
          (el.or[0].and && el.or[0].and.length && el.or[0].and[0].member && el.or[0].and[0].member.split('.')[1] === currentFilterName.split('.')[1])
        );
      });
      if (filterIndex > -1) {
        filters[filterIndex] = currentFilter;
        setFilters([...filters]);
      } else {
        const newAudienceFilters = [...filters];
        newAudienceFilters.push(currentFilter);
        setFilters(newAudienceFilters);
      }
    } else if (currentFilter && currentFilter.or && currentFilter.or.length === 0) {
      const filterIndex = filters.findIndex((el: any) => (
        !!el.or && el.or.length &&
        (
          (el.or[0].member && el.or[0].member.split('.')[1] === currentFilterName.split('.')[1]) ||
          (el.or[0].and && el.or[0].and.length && el.or[0].and[0].member && el.or[0].and[0].member.split('.')[1] === currentFilterName.split('.')[1])
        )
      ));
      if (filterIndex > -1) {
        filters.splice(filterIndex, 1);
        setFilters([...filters]);
      }
    }
  };

  const modifyFilters = (filters: AudienceFilter[], dontModify?: boolean) => {
    const modifiedFilters = filters.reduce((prevValue: AudienceFilter[], currentValue: any) => {
      const sameFilter = prevValue.find((item: AudienceFilter) => (
        // eslint-disable-next-line max-len
        item.member && currentValue.member && item.member.split('.')[1] === currentValue.member.split('.')[1] && item.operator === currentValue.operator
      ));
      if (sameFilter && sameFilter.values && sameFilter.values.length) {
        if (dontModify) {
          prevValue.push(currentValue);
        } else {
          sameFilter.values = [...new Set([...sameFilter.values, ...currentValue.values])];
        }
      } else {
        prevValue.push(currentValue);
      }
      return prevValue;
    }, []);
    return modifiedFilters;
  };

  const handleRemoveAudienceFilter = (
    filter: AudienceFilter | UnaryFilter, filters: AudienceFilter[], setFilters: (value: AudienceFilter[]) => void
  ) => {
    const foundFilterIndex = filters.findIndex((data: AudienceFilter) => {
      const newData = {
        ...data,
        member: data.member ? data.member.split('.')[1] : '',
      };
      const newFilter = {
        ...filter,
        member: filter.member ? filter.member.split('.')[1] : '',
      };
      return areObjectSame(newData, newFilter);
    });
    if (foundFilterIndex > -1) {
      filters.splice(foundFilterIndex, 1);
      setFilters([ ...filters ]);
    }
  };

  const handleRemoveFilterValue = (
    filter: AudienceFilter | UnaryFilter, newValues: string[], filters: AudienceFilter[], setFilters: (value: AudienceFilter[]) => void
  ) => {
    const foundFilterIndex = filters.findIndex((data: AudienceFilter) => {
      const newData = {
        ...data,
        member: data.member ? data.member.split('.')[1] : '',
      };
      const newFilter = {
        ...filter,
        member: filter.member ? filter.member.split('.')[1] : '',
      };
      return areObjectSame(newData, newFilter);
    });
    if (foundFilterIndex > -1) {
      filters[foundFilterIndex].values = [...newValues];
      setFilters([...filters]);
    }
  };

  const handleAddDateFilter = (filter: AudienceFilter | UnaryFilter, filters: AudienceFilter[], setFilters: (value: AudienceFilter[]) => void) => {
    if (filter.values && filter.values.length > 0) {
      const filterExists = filters.find((item: AudienceFilter | UnaryFilter) => (
        item.member && filter.member && item.member.split('.')[1] === filter.member.split('.')[1] && item.operator === filter.operator
      ));
      if (!filterExists) {
        const newAudienceFilters = [...filters];
        newAudienceFilters.push(filter);
        setFilters(newAudienceFilters);
      } else {
        filterExists.values = [...filter.values];
        setFilters([...filters]);
      }
    }
  };

  const areObjectSame = (object1: object, object2: object): boolean => {
    for (let prop in object1) {
      if (object1.hasOwnProperty(prop)) {
        if (typeof object1[prop] !== 'object' && typeof object2[prop] !== 'object') {
          if (object1[prop] !== object2[prop]) {
            return false;
          }
        } else if (
          object2.hasOwnProperty(prop) &&
          !object1[prop]
            .every((value: string | null) => value !== null ? object2[prop].includes(value) : object2[prop].some((el: string | null) => el === null))
        ) {
          return false;
        }
      }
    }
    for (let prop in object2) {
      if (object2.hasOwnProperty(prop)) {
        if (typeof object1[prop] !== 'object' && typeof object2[prop] !== 'object') {
          if (object1[prop] !== object2[prop]) {
            return false;
          }
        } else if (
          object1.hasOwnProperty(prop) &&
          !object2[prop]
            .every((value: string | null) => value !== null ? object1[prop].includes(value) : object1[prop].some((el: string | null) => el === null))
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const getQuery = (
    pageNo: number, pageSizeNo: number, sortColumn: string, sortValue: 'asc' | 'desc', filters: AudienceFilter[], additionalColumns?: boolean
  ): Query => {
    const extraColumns: string[] = additionalColumns === true ?
      ['TAM.first_name_per', 'TAM.last_name_per', 'COMPANY.website_org', 'COMPANY.company_name_org',
        ...companyColumns.map((item: GridColDef) => item.field)] : [];
    return {
      dimensions: [
        ...peopleColumns.map((column: GridColDef) => column.field),
        'TAM.linkedin_url_per', 'TAM.rbid_pao', 'TAM.lower_job_title_per', 'COMPANY.website_org', 'COMPANY.linkedin_url_org',
        ...extraColumns
      ],
      order: [[sortColumn, sortValue]],
      limit: pageSizeNo,
      offset: pageNo * pageSizeNo,
      filters: modifyJobTitleFilter(filters),
    };
  };

  const getCompanyQuery = (
    pageNo: number, pageSizeNo: number, sortColumn: string, sortValue: 'asc' | 'desc', filters: AudienceFilter[], additionalColumns?: boolean
  ): Query => {
    const extraColumns: string[] = additionalColumns === true ? ['COMPANY.company_name_org'] : [];
    return {
      dimensions: [...companyColumns.map((column: GridColDef) => column.field), 'COMPANY.rbid_org', 'COMPANY.website_org', ...extraColumns],
      order: [[sortColumn, sortValue]],
      limit: pageSizeNo,
      offset: pageNo * pageSizeNo,
      filters: modifyJobTitleFilter(filters),
    };
  };

  const modifyJobTitleFilter = (filters: (AudienceFilter | CombinedAudienceFilter)[]) => {
    return filters.map((item: AudienceFilter | CombinedAudienceFilter) => {
      if (item.hasOwnProperty('member')) {
        const isJobTitleColumn = item['member'].split('.')[1] === 'job_title_per';
        const isFullNameColumn = item['member'].split('.')[1] === 'name_per';
        const fullNameContainsOperator = item['operator'] === 'contains' || item['operator'] === 'notContains';
        if (item && item.hasOwnProperty('member') && isJobTitleColumn) {
          return {
            ...item,
            member: 'TAM.lower_job_title_per',
            values: item['values'].map((value: string) => value.toLowerCase())
          };
        } else if (item && item.hasOwnProperty('member') && isFullNameColumn && fullNameContainsOperator) {
          return {
            ...item,
            member: 'TAM.full_name_per',
            values: item['values'].map((value: string) => value.split('-*-').length ? value.split('-*-')[0] : value)
          };
        } else {
          return item;
        }
      } else {
        return item;
      }
    });
  };

  return (
    <NewSegmentContext.Provider
      value={{
        newSegmentFilters,
        setNewSegmentFilters,
        handleAddFilter,
        handleAddNumericFilter,
        handleCombinedNumericFilter,
        modifyFilters,
        handleRemoveAudienceFilter,
        handleRemoveFilterValue,
        handleAddDateFilter,
        getQuery,
        getCompanyQuery,
        areObjectSame,
        modifyJobTitleFilter,
      }}
    >
      {children}
    </NewSegmentContext.Provider>
  );
};
